<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #dfe8f4 !important; height: 30em !important;">
                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/snake.svg" style="height: 10rem !important" />
                <div class="mt-5" v-if="mode == 'add'">
                    Save or close the Previous Address , To "Add New Address"
                </div>
                <div class="mt-5" v-else>
                    Save or close the Previous Address , To "Edit Address"
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">        
                    <button
                        type="button"
                        class="btn btn-new-primary btn-smm"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideSelf"
                    >OK</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name", "mode"],
    methods: {
        hideSelf() {
            this.$modal.hide(this.modal_name);
            setTimeout(() => {
                this.$emit('deactivateEditMode')
            }, 1000)
        }
    }
}
</script>
<style>
    ::placeholder{
        color: #adadad !important;
        font-weight: 400 !important;
    }
</style>